
import React,{useState, useRef } from 'react';
import { nanoid } from 'nanoid';
import Modes from './Modes';
import { DropdownWrapper, DropdownList, Selected } from '../Base/twStyled/DropdownWrapper';

const Header = ({ handleOpen, handleClose, handleMode ,open}) => {

	const [displayItem, setDisplayItem] = useState("Normal")

	const MixedFunc = (item) =>{
		handleClose()
		handleMode(item)
		setDisplayItem(item)
	}
	const listItems = Modes.map((item) => (
        <DropdownList
            key={`${nanoid(5)}-${item}`}
            onClick={() => MixedFunc(item)}
        >
            {item}
        </DropdownList>
    ));

	return (
		<div className='header flex items-center justify-between ' >
		
			<h1 className='font-bold text-[32px] my-[20px]'>Notes</h1>
			<div className="place-content-end ">
				<Selected onClick={handleOpen} open={open } className="my-[24px]" >
					{displayItem}
				</Selected>

			

		
				<DropdownWrapper open={open} >
					{listItems}
				</DropdownWrapper>

			</div>
		</div>
	);
};

export default Header;