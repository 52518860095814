import React from 'react'

const Modes = [
    "Normal",
    "Dark-Theme",
    "Pink-Theme",
    "Green-Theme",
    "Orange-Theme",
    "Check-ExTheme"
]

export default Modes