import tw from "tailwind-styled-components"




const Container = tw.div`


max-w-[960px]
mr-auto
ml-auto
pr-[15px]
pl-[15px]
min-h-full
`

export default Container