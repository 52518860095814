import tw from "tailwind-styled-components"

const DropdownWrapper = tw.ul`
    max-h-[300px] 
    overflow-y-auto 
    top-[63px]
    absolute
    z-10 
    list-none 
    mt-[2px] 
    rounded 
    bg-white 
    shadow-box 
    p-0
    rounded-md
    shadow-md
    ${(props) => (props.open ? 'visible' : 'invisible')}
`
const DropdownList = tw.li`
    hover:bg-indigo-600
    cursor-pointer 
    py-3
    hover:text-white
    px-6
    w-full
`

const Selected = tw.div`
    px-6
    py-2
    cursor-pointer
    h-[40px]
    border-[1px] 
    bg-white
    border-slate-300 
    rounded-md
    ${(props) => (props.open ? 'invisible' : 'visible')}
`
export  {DropdownWrapper,DropdownList,Selected}