import Note from './Note';
import AddNote from './AddNote';
import { nanoid } from 'nanoid';
const NotesList = ({
	notes,
	handleAddNote,
	handleDeleteNote,
	key,
}) => {
	return (
		<div className='notes-list'>
			{notes.map((note,index) => (
				<Note
					key={note.key}
					id={note.id}
					title={note.title}
					content={note.content}
					date={note.date}
					handleDeleteNote={handleDeleteNote}
				/>
			))}
			<AddNote handleAddNote={handleAddNote} />
		</div>
	);
};

export default NotesList;
