import React,{ useState, useEffect } from 'react';

import {Helmet} from "react-helmet";
import { nanoid } from 'nanoid';
import NotesList from './components/NotesList';
import Search from './components/Search';
import Header from "./components/Header"
import Theme from "./Base/twStyled/Theme"
import Container from './Base/twStyled/Container';
import Modes from './components/Modes';
import { Style } from './Base/Style';

// console.log(Modes[0])

const App = () => {
	
	const [notes, setNotes] = useState([]);
	const [search, setSearch] = useState('');


  
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = useState("Normal")
  {mode == Modes[0] && (document.body.style = 'background: white')}
  {mode == Modes[1] && (document.body.style = 'background: rgb(27,28,36)')}
  {mode == Modes[2] && (document.body.style = 'background: rgb(252 231 243)')}
  {mode == Modes[3] && (document.body.style = "background: rgb(236 252 203)")}
  {mode == Modes[4] && (document.body.style = "background: rgb(254 215 170)")}
  {mode == Modes[5] && (document.body.style = "background: rgb(254 226 226)")}



  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };



  

	useEffect(() => {
		const savedNotes = JSON.parse( 
			localStorage.getItem('note') //str  => obj
		) 
		setNotes(savedNotes || []); // setDefaultbcuz null


	}, []);

	useEffect(() => {
		if(notes.length>0){
			localStorage.setItem('note',
			JSON.stringify(notes) // obj => string
		);
		}
		
	}, [notes]);


	const addNote = (note) => {
      //note = obj =>{
      // title: "something",
      // content: "something"
      // }
		const date = new Date();
		const newNote = {
			key: nanoid(),
			id: nanoid(),
			title: note.title,
      		content: note.content,
			date: date.toLocaleDateString(),

		};

		setNotes(prevValue=> [...prevValue, newNote]);

	};

	const deleteNote = (id) => {
		setNotes(notes.filter((note) => note.id !== id)); //replace with empty []

	};
  
  
	return (

		<Theme className={`${(mode)}`}>
     		<Container className='container'>
			<Header
			handleMode = {setMode}
			handleOpen = {handleOpen}
			handleClose ={handleClose}
			open = {open}
			/>
			<Search handleSearchNote={setSearch} />


			<NotesList
				notes={notes.filter((note) =>
					note.title.toLowerCase().includes(search)
				)}
				handleAddNote={addNote}
				handleDeleteNote={deleteNote}
				// key ={notes.key}
			/>

			</Container>
		</Theme>
	);
};

export default App;
