import React from 'react';
import { MdSearch } from 'react-icons/md';
import SearchCom from '../Base/twStyled/SearchCom';
const Search = ({ handleSearchNote }) => {
	return (
		<SearchCom className='thai-font search'>
			<MdSearch className='search-icon' size='1.3em' />
			<input 
				className='border-none bg-inherit w-full outline-none text'
				onChange={(event) =>
					handleSearchNote(event.target.value.toLocaleLowerCase())
				}
				type='text'
				placeholder='Search for titles...'
			/>
		</SearchCom>
	);
};




export default Search;
