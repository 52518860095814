import tw from "tailwind-styled-components"

const SearchCom = tw.div`
flex
items-center
bg-sky-100
rounded-[8px]
p-[5px]
mb-[24px]
`

export default SearchCom