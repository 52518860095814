import { MdDeleteForever } from 'react-icons/md';
import Footer from '../Base/twStyled/Footer';
import tw from 'tailwind-styled-components'

const Note = ({ id, title, content, date, handleDeleteNote }) => {
	const NoteCom = tw.div`
	bg-blue-200 rounded-[10px] min-h-[180px] p-4 flex flex-col justify-between whitespace-pre-wrap
	`
	return (
		<NoteCom className='note'>
			<div className='break-words font-bold reg-title mb-[8px] text'>{title}</div>
			<div className='break-words reg-font mb-auto text'>{content}</div>
			<Footer>
				<small className='font-bold text'>{date}</small>
				<MdDeleteForever
					onClick={() => handleDeleteNote(id)}
					className='cursor-pointer place-items-end delete-icon'
					size='1.3em'
				/>
			</Footer>
		</NoteCom>
	);
};

export default Note;