import React,{ useState } from 'react';
import tw from "tailwind-styled-components"
import Footer from '../Base/twStyled/Footer';
import newNote from '../Base/twStyled/Newnote';
const AddNote = ({ handleAddNote }) => {

	const [note, setNote] = useState({
		title: "",
		content: ""
	  });

	const characterLimit = 350;

	const handleChange = (event) => {
		const { name, value } = event.target;
	
		if (characterLimit - event.target.value.length >= 0) {
			setNote((prevNote) => {
				return {
				  ...prevNote,
				  [name]: value
				};
			  });
		}
		
	  }

	const handleSubmit = (e) => {
		e.preventDefault()
		if (note.content.trim().length > 0 && note.title.trim().length> 0) { //dont include space
			handleAddNote(note);
			setNote({
				title: "",
				content: ""
			  });	//clear board
		}

	};
	
	return (

		<div className={`${newNote} add-note`} > {/* cannot use styled component */}
			<input
				name="title"
				onChange={handleChange}
				value={note.title}
				placeholder="Title..."
				className='bg-inherit outline-none reg-title mb-[8px] text'
			/>
			<textarea 
				className='reg-font bg-inherit resize-none outline-none border-none text'
				spellCheck="false"
				name = "content"
				rows='7'
				cols="3"
				placeholder='Add note...'
				value={note.content}
				onChange={handleChange}
			/>

			<Footer>
				<small className='font-bold text'>
					{characterLimit - (note.content.length)} Remaining
				</small>
				<button className='save' onClick={handleSubmit}>
					Add
				</button>
			</Footer>
		</div>
	);
};

export default AddNote;
